import * as packageJson from './../package.json';
console.log('VITE_VUE_APP_ENV: ', import.meta.env.VITE_VUE_APP_ENV);
console.log('app.version - package.json: ', packageJson.version);
import { version } from 'vue'
console.log('Vue Version: ', version);
console.log('MODE: ', import.meta.env.MODE);

import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

/**
 * Bugsnag has to be the first initialized plugin
 */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: import.meta.env.VITE_VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.VITE_VUE_APP_ENV,
  appVersion: packageJson.version,
  enabledReleaseStages: ['production'],
});
const bugsnagVue = Bugsnag.getPlugin('vue');
app.use(bugsnagVue);

import store from './components/store/mainStore';
app.use(store);

import router from './router';
import './plugins/routeGuard';
app.use(router);

import { VueCookieNext } from 'vue-cookie-next';
app.use(VueCookieNext);
VueCookieNext.config({
  expire: '7d',
  path: '/',
  domain: '',
  secure: '',
  sameSite: '',
});

//region FontAwesome
// Info see: https://fontawesome.com/docs/web/use-with/vue/add-icons
// noinspection ES6UnusedImports
import './plugins/fontawesome'; // To use an Icon, it has to be added in this File here.
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
app.component('font-awesome-icon', FontAwesomeIcon);
//endregion

import './styles/index.css';
/**
 * Logs User in from cookie, if present
 */
if (store.getters.isGuest) {
  var user = VueCookieNext.getCookie('user');
  if (user && user !== 'null') {
    store.commit('setUser', user);
    store.commit('setCompanies', (user.employees !== undefined ? user.employees : []));
    Bugsnag.addMetadata('user-cookie', { id: user.id, email: user.email });
  }
  var cookies = VueCookieNext.getCookie('cookies');
  if (!cookies || cookies === 'false') {
    store.commit('setCookiesAccepted', false);
  }
}

import VueAxios from 'vue-axios';
import authApi from './components/connections/auth';
import leadFormApi from './components/connections/leadForm';
app.use(VueAxios, authApi);
app.use(VueAxios, leadFormApi);

/**
 * global Function used in all focusActivities
 */
app.mixin({
  methods: {},
});

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
const toastOptions = {
  useDefaultCss: false,
  // You can set your default toastOptions here
};
app.use(Toast, toastOptions);

import { initGtm } from './plugins/gtm.js';
initGtm();

app.mount('#app');
